import React, { useState, useEffect } from "react";
import { getDocs, query, where, addDoc, collection, orderBy, serverTimestamp, doc, deleteDoc} from "firebase/firestore";
import { db, auth } from "../firebaseConfig";
import { useNavigate } from "react-router-dom";

function Posts({ isAuth }) {
  const [title, setTitle] = useState("");
  const [postText, setPostText] = useState("");
  const [accountPostList, setAccountPostList] = useState([]);
  const [accountCommentList, setAccountCommentList] = useState([]);
  const [createdAt, setCreatedAt] = useState();
  const postsCollectionRef = collection(db, "posts");
  //const commentsPostRef = collection(db, "comments");
  const commentsCollectionList = collection(db, 'comments');

  let navigate = useNavigate();


  const deletePost = async (authorId) => {
    const postBlog = doc(db, "posts", authorId);
    //const commentAuthor = getDocs(query(commentsPostRef, where('respond', '==',authorId)));

    //await commentAuthor.map((comment) => deleteDoc(doc(db, 'comments', comment.id)));
    await deleteDoc(postBlog);
  };

  const deleteComment = async (authorId) => {
    const commentBlog = doc(db, "comments", authorId);
    await deleteDoc(commentBlog)
  }

  useEffect(() => {
    const getAccountPosts = async () => {
      const postData = await getDocs(query(postsCollectionRef, where('authorName', '==', auth.currentUser.displayName)));
      setAccountPostList((postData.docs.map((doc) => ({ ...doc.data(), id: doc.id}))));
    };
    const getAccountComments = async () => {
      const postData = await getDocs(query(commentsCollectionList, where('commentAuthor', '==', auth.currentUser.displayName)));
      setAccountCommentList((postData.docs.map((doc) => ({ ...doc.data(), id: doc.id}))));
    };
    getAccountPosts();
    getAccountComments();
  }, [deletePost, deleteComment])

  const createPost = async () => {
    setCreatedAt(serverTimestamp);
    await addDoc(postsCollectionRef, {
      title, 
      postText, 
      authorName: auth.currentUser.displayName, 
      authorId: auth.currentUser.uid, 
      createdAt: serverTimestamp(),
    });
    
    navigate("/Forum");
  };

  useEffect(() => {
    if (!isAuth) { 
        navigate("/");
    }
  }, []
  );

  return (
    <div className="fullPage">
      <div className="createPostPage">
        <div className="cpContainer">
          <h1>Create A Post</h1>
          <div className="inputGP">
            <p> Title:</p>
            <input className="textArea"
              placeholder="Title..."
              onChange={(event) => {
                setTitle(event.target.value);
              }}
            />
          </div>
          <div className="inputGP">
            <p> Post:</p>
            <textarea className="textArea"
              placeholder="Post..."
              onChange={(event) => {
                setPostText(event.target.value);
              }}
            />
          </div>
          <button onClick={createPost}> Submit Post</button>
        </div>
      </div> 
      <div className="accountPage">
        <div className="listDiv">
        {accountPostList.map((post) => {
            return (
              <div className="post">
                <div className="parentTitleDiv">
                  <h2 className="postTitle">{post.title}</h2>
                  <button className="buttonDiv" onClick={() => {
                    deletePost(post.id);
                  }}>
                    {""}
                    &#128465;
                  </button>
                </div>
                <div className="forumDiv">
                  <h3>{post.authorName} @ {post.createdAt.toDate().getMonth() + 1}/{post.createdAt.toDate().getDate()}/{post.createdAt.toDate().getFullYear()}</h3>
                  <p className="postText">{post.postText}</p>
                </div>
              </div>
            );
          })
        }
        </div>
        <div className="listDiv">
        {accountCommentList.map((comment) => {
          return (
            <div className="post">
              <button className="buttonDiv" onClick={() => {
                  deleteComment(comment.id);
                  }}>
                    {""}
                    &#128465;
                </button>
              <h4>{comment.commentAuthor} to [{comment.commentPostTitle}] by {comment.commentPostAuthor}</h4>
              <h4>---- {comment.commentText}</h4>
            </div>
          );
          })
        }
        </div>
      </div>
    </div>
  );
}

export default Posts;