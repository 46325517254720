import React from "react";


function About() {
    return (
        <div className="alphaAboutDiv">
                <h1>Welcome to the NAVSPACE</h1>
                    <h3>built, made and maintained by Nav Dontuboyina</h3>
                    <div className="qouteDiv">
                        <div className="pic">
                        <div className="qoute">
                            <h3>"Sometimes you're ahead, sometimes you're behind.</h3>
                            <h3> The race is long, and in the end, it's only with yourself."</h3>
                            <h2>- Baz Luhrmann</h2>
                        </div>
                        <img className="navImage" src="/images/nav_d_pic.jpg" />
                        </div>
                    </div>
                    <div className="genericDiv">
                        <h2>Background</h2>
                            <div className="para">
                            <p> Hello, Welcome to NavSpace by Navaneeth Dontuboyina AKA Nav D - an ambitious high schooler who wants to make a positive global impact to create a happy community. BTW, if you are revisiting this website, thanks for checking back in.</p>
                            <p> Nav was born in India but soon moved to America and ever since he loves to learn about challenges around him and think of possible solutions in order to pursue them.</p>
                            <p> This website is to display my pursuits and to connect with others having similar passions.</p>
                            </div>
                    </div>
                    <div className="genericDiv">
                        <h2>Activities</h2>
                            <div className="para">
                            <p> Nav is interested in many extracurricular activities and so for you to get a better view of him as a person here's a quick summary.</p>
                            <p> Nav is interested with a variety of extracurricular clubs in school. A broad category of these groups is STEM focused such as his participation in the Robotics, ML and Programming Club. However his interest go beyond STEM as he is a staff writer in his school newspaper, plans on joining the new Mock Trial team, and hosts his own podcast where he reviews books of all genres and themes in mordern media.</p>
                            <p> At this point, Nav is still developing his passion and interests as a person as he is constantly learning, creating and experimenting with diffrent hobbies. However, he will always do so with full commitment and focus.</p>
                            </div>
                    </div>
                    <div className="genericDiv">
                        <h2>Links</h2>
                            <div className="para">
                            <p>Anyways, if you want to check out some of my work on other projects - here are some links!</p>
                            <p><a href="https://github.com/ndontubo">GITHUB:</a> Please check out my programming projects.</p>
                            <p><a href="https://www.linkedin.com/in/nav-dontuboyina/">LINKEDIN:</a> Here goes his LinkedIn professional and comprehensive profile.</p>
                            <p><a href="https://open.spotify.com/show/1U2tM1DkM8djk1Iv9O8Sne">PODCAST-SPOTIFY,</a> <a href="https://anchor.fm/nav-d8">PODCAST-ANCHOR:</a> If you want to check out his book reviews and views on them.</p>
                            <p><a href="https://thelancer.sfhs.com/2020/11/15/navaneeth-dontuboyina/">NEWSPAPER:</a> Here are some of the articles Nav has written on STEM topics on his school newspaper as a staff writer.</p>
                            <p><a href="https://usatt.simplycompete.com/userAccount/up/173450">TABLE TENNIS:</a> Nav's table tennis profile and rankings.</p>
                            </div>
                    </div>
        </div>
    );
}

export default About;