import './App.css';
import { createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword, signOut } from "firebase/auth";
import React, { useState } from 'react';
import { signInWithPopup } from "firebase/auth";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { auth, provider } from './firebaseConfig.js';
import About from './pages/About';
import Posts from './pages/Posts';
import Forum from './pages/Forum';
import Updates from './pages/Updates'

function App() {
    const [isAuth, setIsAuth] = useState(localStorage.getItem("isAuth"));
    const [username, setUserName] = useState("Guest");


    const signUserOut = () => {
        signOut(auth).then(() => {
            localStorage.clear();
            setIsAuth(false);
            setUserName("Guest");
            window.location.pathname = "/Posts";
        });
    };

    const signInWithGoogle = () => {
        signInWithPopup(auth, provider).then((result) => {
          localStorage.setItem("isAuth", true);
          setIsAuth(true);
          setUserName(auth.currentUser.displayName);
        });
      };


    return(
    <Router>
        <nav className="navBar">
            <h1 className="headlineNavbar"> NavSpace THINGS FOR EVERYTHING NAV</h1>
            <div>
                <Link className="Link" to="/">About</Link>
                <Link className="Link" to="/Forum">Forum</Link>
                <Link className="Link" to="/Updates">Updates</Link>
                {!isAuth ? (
                    <div className="accountDiv">
                        <p className="username">{username}</p>
                        <button Link className="signoutButton" onClick={signInWithGoogle}> Login </button>
                    </div>
                ) : (
                    <>
                        <Link className="Link"to="/Posts">Account</Link>
                        <div className="accountDiv">
                            <p className="username">{username}</p>
                            <button className="signoutButton" onClick={signUserOut}> Logout</button>
                        </div>
                    </>
                )}
            </div>
        </nav>
        <Routes>
            <Route path="/" element={<About isAuth={isAuth} />} />
            <Route path="/Posts" element={<Posts isAuth={isAuth} />} />
            <Route path="/Forum" element={<Forum isAuth={isAuth} />} />
            <Route path="/Updates" element={<Updates isAuth={isAuth}/>} />
        </Routes>
    </Router>
    );
}

export default App;