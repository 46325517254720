import React, { useState, useEffect } from "react";

function Updates() {
    return (
        <div className="alphaAboutDiv">
            <h1>UPDATES</h1>
                <h2></h2>
                <h3>Things that Nav is planning to change in betterment of NavSpace...hopefully every monthly.</h3>
                <h3>Everytime a change is updated it will be boldened for a week and then removed</h3>
                <div className="genericDiv">
                    <h2>Forum</h2>
                        <h3>Updated</h3>
                        <div className="para">
                            <p>/    None so far....</p>
                        </div>
                        <h3>To Update</h3>
                        <div className="para">
                            <p>/    The comments should be expandable and compacted and you should be able to reply to more comments. This would be done by making a new collection in my firestore database.</p>
                            <p>/    An upvote system that could be used to filter the posts and thus would lead to more filters similar to Reddit's system.</p>
                            </div>
                </div>
                <div className="genericDiv">
                    <h2>Account</h2>
                        <h3>Updated</h3>
                            <div className="para">
                                <p>/    Making it so you dont have to reload to create a post and should be able to redirect to the forum page when you login.</p>
                                <p>/    An account display so you can see whom you are logged in as (current user displayName from firestore/firebase is being very picky).</p>
                                <p>/    Having a post and comment manager where you can delete your activities.</p>
                            </div>
                        <h3>To Update</h3>
                            <div className="para">
                                <p>/    The account is still buggy and the posts and the forum will tab out. I probably will have to transfer my database from Firebase to MongoDB - I still want to maintain a non-SQL database.</p>
                            </div>
                </div>
                <div className="genericDiv">
                    <h2>Overall</h2>
                        <h3>Updated</h3>
                            <div className="para">
                            <p>/    A more pleasant color sceme and better format in general with divs, etc.</p>
                            </div>
                        <h3>To Update</h3>
                            <div className="para">
                                <p>/    Make a mailing system so you guys can reach me more efficiently.</p>
                            </div>
                </div>

        </div>
    );
}

export default Updates;