import React, { useEffect, useState } from "react";
import { query, getDocs, collection, deleteDoc, doc, orderBy, where, addDoc, serverTimestamp} from "firebase/firestore";
import { auth, db } from "../firebaseConfig";
import { useNavigate, Route, Routes, Router, Link, nav } from "react-router-dom";


function Forum({isAuth}) {
  const [postList, setPostList] = useState([]);
  const [commentList, setCommentList] = useState([]);
  const [filterOptions, setFilterOptions] = useState("");
  const [commentPostID, setCommentPostID] = useState('');
  const [commentPostAuthor, setCommentPostAuthor] = useState('');
  const [commentPostTitle, setCommentPostTitle] = useState('');
  const [commentText, setCommentText] = useState("");
  const postsCollectionRef = collection(db, "posts");
  const commentsCollectionList = collection(db, 'comments');

  const getForum = () => {
    const getPosts = async () => {
      if (filterOptions == 'navBlog') {
        const data = await getDocs(query(postsCollectionRef, where('authorName', '==', 'Nav D')));
        setPostList((data.docs.map((doc) => ({ ...doc.data(), id: doc.id}))));
      } else if (filterOptions == 'all') {
        const data = await getDocs(query(postsCollectionRef, orderBy('createdAt', 'desc')));
        setPostList((data.docs.map((doc) => ({ ...doc.data(), id: doc.id}))));
      }
    };
    getPosts();
  };

  useEffect(() => {
    const getComments = async() => {
      const commentData = await getDocs(query(commentsCollectionList, orderBy('createdAt', 'desc')));
      setCommentList((commentData.docs.map((doc) => ({ ...doc.data(), id: doc.id}))));
    };
     getComments();
  });

  const commentPost = async() => {
    await addDoc( commentsCollectionList, {
      commentText,
      commentAuthor: auth.currentUser.displayName,
      id: auth.currentUser.uid, 
      createdAt: serverTimestamp(),
      commentPostAuthor,
      commentPostTitle,
      respond: commentPostID,
    });
  };

  const options = [
    {
      label:'select',
      value:"",
    },   
    {
      label: "All Posts",
      value: "all",
    },
    {
      label: "Nav's Blog",
      value: "navBlog"
    }
  ]

  return (
    <div className="forumPage">
      <h1 className="forumHead">Blog & Community</h1>
      <div className="parent">
        <div className='posts'>
          <div className="parentTitleDiv">
            <h2 className="filterHeader">Select a FILTER</h2>
            <div className="optionDiv">
              <select className='submit' onChange={(event) => {
                setFilterOptions(event.target.value);
                }}>
                {options.map((option) => (
                  <option value={option.value}>{option.label}</option>
                ))}
              </select>
              <button type='submit' className='submit' onClick={getForum}>SUBMIT</button>
            </div>
          </div> 
        {postList.map((post) => {
          return (
            <div className="post">
              <div className="parentTitleDiv">
                <h2 className="postTitle">{post.title}</h2>
              </div>
              <div className="forumDiv">
              <h3>{post.authorName} @ {post.createdAt.toDate().getMonth() + 1}/{post.createdAt.toDate().getDate()}/{post.createdAt.toDate().getFullYear()} {post.createdAt.toDate().getHours()}:{post.createdAt.toDate().getMinutes()}</h3>
              <p className="postText">{post.postText}</p>
              <div className="commentsDiv">
                <textArea 
                  className='commentBody' 
                  placeholder="Comment..." 
                  onChange={(event) => {
                    setCommentText(event.target.value);
                    setCommentPostID(post.id);
                    setCommentPostAuthor(post.authorName);
                    setCommentPostTitle(post.title)
                  }}
                />
                <button className="commentButton" onClick={commentPost}>COMMENT</button>
              </div>
              </div>
              <div className="commentSection">
                {commentList.map((comment) => {
                      if (comment.respond == post.id) {
                        return(
                          <div className="commentBox">
                            <h4>{comment.commentAuthor}</h4>
                            <h4>---- {comment.commentText}</h4>
                          </div>
                        );
                      }
                    })
                  }
              </div>
            </div>
          );
        })
        }
        </div>
        <div className="forumSides">
          <h4 className="sidesHead">PURPOSE</h4>
          <div className="sidesPoints">
            <p>- Intended as a weekly Blog for Nav to share his thoughts on topics.</p>
            <p>- Can also be used a community forum for people to generate conversation and discuss topics.</p>
          </div>
          <h4 className="sidesHead">RULES</h4>
          <div className="sidesPoints">
            <p>- Other than BASIC forum ettiqutte such as no NSFW posts, no vulgur language, or spam, etc. </p>
            <p>- There will be no discrimination at all on this site in any form to any group of people.</p>
            <p>- Anything posted by anyone is subjected to change based on Nav's Descrietion as any content on NavSpace is property of NavSapce.</p>
          </div>
          <h4 className="sidesHead">NAV's BLOG</h4>
          <div className="sidesPoints">
            <p>- If you want a chornological collection of Nav's Blog Posts</p>
            <p>- To view a comprehensive collection of NavSpace Forum Rules click the filter.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Forum;